define('client/controllers/application', ['exports', 'client/mixins/reset-scroll-position'], function (exports, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { computed } = Ember;

  exports.default = Ember.Controller.extend(_resetScrollPosition.default, {
    activeRoute: "home",
    menuClass: computed('activeRoute', function () {
      const res = { home: "", "sign-up": "", workshopContent: "", about: "", contact: "" };
      for (const property in res) {
        if (res.hasOwnProperty(property)) {
          if (this.get('activeRoute') == property) {
            res[property] = "active";
          } else {
            res[property] = "";
          }
        }
      }
      return res;
    })

  });
});