define('client/routes/sign-up', ['exports', 'client/mixins/reset-scroll-position'], function (exports, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {
    model() {
      return Ember.RSVP.hash({
        courses: this.store.findAll('course'),
        changeWeeks: this.store.findAll('change')
      });
    },
    actions: {
      willTransition(transition) {
        console.log(transition.targetName);
        const parentController = this.controllerFor('application');
        parentController.set('activeRoute', transition.targetName);
      }
    }
  });
});