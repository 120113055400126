define('client/initializers/flash-messages', ['exports', 'client/config/environment', 'ember-cli-flash/utils/flash-message-options'], function (exports, _environment, _flashMessageOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  /* eslint-disable ember/new-module-imports */
  const INJECTION_FACTORIES_DEPRECATION_MESSAGE = '[ember-cli-flash] Future versions of ember-cli-flash will no longer inject the service automatically. Instead, you should explicitly inject it into your Route, Controller or Component with `Ember.inject.service`.';
  function initialize() {
    const application = arguments[1] || arguments[0];
    const { flashMessageDefaults } = _environment.default || {};
    const { injectionFactories } = flashMessageDefaults || [];
    const options = (0, _flashMessageOptions.default)(flashMessageDefaults);
    const shouldShowDeprecation = !(injectionFactories && injectionFactories.length);

    Ember.deprecate(INJECTION_FACTORIES_DEPRECATION_MESSAGE, shouldShowDeprecation, {
      id: 'ember-cli-flash.deprecate-injection-factories',
      until: '2.0.0'
    });

    options.injectionFactories.forEach(factory => {
      application.inject(factory, 'flashMessages', 'service:flash-messages');
    });
  }

  exports.default = {
    name: 'flash-messages',
    initialize
  };
});