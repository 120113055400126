define('client/components/sign-up', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { computed } = Ember;

  exports.default = Ember.Component.extend({
    activeCarpet: null,
    carpetClass: computed('activeCarpet', function () {
      const res = { "video": "", "01": "", "02": "", "03": "", "04": "", "05": "", "06": "", "07": "", "08": "", "09": "" };
      for (const property in res) {
        if (res.hasOwnProperty(property)) {
          if (this.get('activeCarpet') == property) {
            res[property] = "active";
          } else {
            res[property] = "";
          }
        }
      }
      return res;
    }),
    actions: {
      carpetChoosed: function (key) {
        console.log(key);
        this.set("activeCarpet", key);
      },
      signUp: function () {
        console.log(this.get("activeCarpet"));
      }
    }
  });
});