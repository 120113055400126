define('client/router', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('home');
    this.route('sign-up');
    this.route('workshops');
    this.route('contact');
    this.route('about');
    this.route('workshopContent');
    this.route('nutrition-counseling');
  });

  exports.default = Router;
});