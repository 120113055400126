define('client/adapters/application', ['exports', 'ember-data/adapters/json-api', 'client/config/environment'], function (exports, _jsonApi, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({

    host: _environment.default.APP.host,
    namespace: _environment.default.APP.namespace,

    pathForType(modelName) {
      const camelized = Ember.String.camelize(modelName);
      return Ember.String.pluralize(camelized);
    }

  });
});