define('client/services/ajax', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    // http://stackoverflow.com/questions/9705773/non-crud-actions-with-ember-data
    call: function (method, type, id, action, hash = null, authorize = true) {
      var owner = Ember.getOwner(this);
      if (authorize === true) {
        var adapter = owner.lookup('adapter:application');
      } else {
        var adapter = owner.lookup('adapter:unauth');
      }

      var url = adapter.buildURL(type, id) + '/' + action;
      if (hash) {
        hash.data = $.extend({}, hash);
      }

      return adapter.ajax(url, method, hash);
    }

  });
});