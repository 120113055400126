define('client/controllers/contact', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { get, inject: { service } } = Ember;

  exports.default = Ember.Controller.extend({
    ajax: service(),
    flashMessages: service(),
    actions: {
      sendForm() {
        const flashMessages = get(this, 'flashMessages');
        this.get('ajax').call('POST', 'contact', null, '', {
          "fullName": this.get('fullName'),
          "email": this.get('email'),
          "phone": this.get('phone'),
          "message": this.get('message')
        }).then(res => {
          this.set('fullName', '');
          this.set('email', '');
          this.set('phone', '');
          this.set('message', '');
          flashMessages.success('Az üzenetet sikeresen elküldtük');
        }).catch(err => {
          console.log(err);
          flashMessages.danger('Az üzenetet küldése sikertelen!');
        });
      }
    }
  });
});