define('client/models/course', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    day: (0, _attr.default)('number'),
    fromHour: (0, _attr.default)('number'),
    fromMinute: (0, _attr.default)('number'),
    toHour: (0, _attr.default)('number'),
    toMinute: (0, _attr.default)('number'),
    changeWeek: (0, _attr.default)('number')
  });
});