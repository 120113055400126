define('client/routes/nutrition-counseling', ['exports', 'client/mixins/reset-scroll-position'], function (exports, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {
    actions: {
      willTransition(transition) {
        const parentController = this.controllerFor('application');
        parentController.set('activeRoute', transition.targetName);
      }
    }
  });
});