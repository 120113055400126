define('client/controllers/sign-up', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { computed, A, get } = Ember;

  exports.default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    selectedOccurence: null,
    changedWeeks: A(),
    occurrences: computed('model.courses', 'model.changeWeeks', 'cancel', function () {
      const res = A();
      const changeWeeks = {};
      this.get('model.changeWeeks').forEach(changeWeek => {
        changeWeeks[`${changeWeek.get('year')}|${changeWeek.get('week')}`] = changeWeek.get('id');
      });

      let weekDate = moment();
      let i = 0;
      while (i < 20) {

        if (i > 0) {
          weekDate.add(7, 'days');
        }

        if (`${weekDate.year()}|${weekDate.week()}` in changeWeeks) {

          this.get('model.courses').forEach(course => {
            if (course.get('changeWeek') == changeWeeks[`${weekDate.year()}|${weekDate.week()}`]) {
              createOccurence(weekDate, course, res);
            }
          });
        } else {
          this.get('model.courses').forEach(course => {
            if (!course.get('changeWeek')) {
              createOccurence(weekDate, course, res);
            }
          });
        }
        i++;
      }
      return res;
    }),
    actions: {
      openOccurence: function (occurrence) {
        console.log(occurrence);
        this.set('selectedOccurence', occurrence);
      },
      cancelSignUp: function () {
        this.set('selectedOccurence', null);
      }
    }
  });


  function createOccurence(weekDate, course, res) {
    const date = weekDate.isoWeekday(course.day);
    res.pushObject(Ember.Object.create({
      title: 'Jóga óra',
      startsAt: date.millisecond(0).second(0).minute(course.get('fromMinute')).hour(course.get('fromHour')).toDate(),
      endsAt: date.millisecond(0).second(0).minute(course.get('toMinute')).hour(course.get('toHour')).toDate()
    }));
  }
});