define('client/routes/workshop-content', ['exports', 'client/mixins/reset-scroll-position'], function (exports, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {
    model: function (params, transition) {
      if (transition.queryParams.direction == 'vegan') {
        return {
          src: '27972916_171071656858939_3642161173760238273_n.jpg',
          subject: 'Jógás-vegán főzős workshopok',
          text: `Pozsonyi Zsuzsanna vegán séffel közösen, szezonálisan tartjuk a workshopokat.<br />
        Az aktuális évszaknak megfelelő jóga gyakorlást kötjük össze vegán főzőcskével, ami remek hangulatban tellik, a hatása pedig egyedülálló a szervezetedre.
        Ha jó társaságban szeretnél lenni, fontos a jóga és a minsőségi változatos étkezés, akkor itt a helyed. `,
          direction: transition.queryParams.direction

        };
      }
      if (transition.queryParams.direction == 'hatha') {
        return {
          src: '47396454_299439767355460_2279129708496945152_o.jpg',
          subject: 'Tematikus hatha és flow jóga workshopok',
          text: `A Jóga különböző lépcsőfokait, jobban elmélyülve gyakoroljuk, időpontok hamarosan!`,
          direction: transition.queryParams.direction

        };
      }
    }
  });
});